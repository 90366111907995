<template>

    <div :class="parentClass" @click.prevent="showReiseplanModal">
        <span class="icon" :class="isInCollectionClass">
            <img :src="getIcon" alt="Bookmark-icon"/>
        </span>
    </div>

</template>
<script>
  import { bus } from '@/main'

  export default {
    name: "bookmarkButton",
    props: {
        parentClass: {
            type: String,
            required: false,
            default: "card-body-icon"
        },
        content: {
            type: Object,
            required: true
        },
    },
    computed: {
        isInCollection(){
            if(this.isLoggedIn && this.isContentInCollection()){
                return true;
            }
            return false;
        },
        isInCollectionClass(){
            if(this.isInCollection == true){
                return 'active';
            }
            return '';
        },
        collections(){
          return this.$store.getters.getCollections;
      },
      isLoggedIn(){
        if(this.$store.getters.getToken){
            return true;
        }
        return false;
    },
    getIcon(){
        if(this.isInCollection){
            return "/assets/logo/tripmeister_weiß.png";
        }
        return "/assets/logo/tripmeister_schwarz.png";
    }
},
methods: {
  showReiseplanModal () {
    bus.$emit('show-reiseplan-modal', {show: true, content: this.content});
},
isContentInCollection(){
    if(this.collections!=null){
        for (var prop in this.collections) {
            var collectionGroup = this.collections[prop];
            for(var i=0; i<collectionGroup.length; i++){
                var currentCollection = collectionGroup[i];
                if(Object.prototype.hasOwnProperty.call(currentCollection, 'contents') && currentCollection.contents!=null){
                    if(currentCollection!=null && currentCollection.contents.length>0){
                        for(var j=0; j<currentCollection.contents.length; j++){
                            if(currentCollection.contents[j].id == this.content.id){
                                return true;
                            }
                        }
                    }
                }
            }
        }
    }
    return false;
},
}
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .active{
    border-color: #E5321B!important;
    background: #E5321B!important;
}

.btn-card .icon{
    margin-top: 5px;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    color: #000000;
}

.btn-card .icon img {
    color: #FFFFFF;
    height: 21px;
}

.card-body-icon {
    position: absolute;
    background: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    right: 8px;
    top: -25px;
    padding: 5px;
}

.card-body-icon .icon {
    text-align: center;
    border: 1px solid #000000;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    color: #000000;
}


.icon img{
    height: 21px;
}

</style>
